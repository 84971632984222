<template>
<form @submit.prevent="FormType == 'add' ? addTrasportation() : updateTransportation()">
    <div class="transportation-form">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="company_name" class="p-col-fixed">Company name*:</label>
                <div class="p-col">
                    <InputText id="company_name" v-model="transportationForm.company_name" type="text" :class="{'p-invalid':v$.company_name.$error || !isCompanyNameValid}" />
                    <small v-if="v$.company_name.$error" class="p-error">{{v$.company_name.required.$message.replace('Value', 'Company name')}}</small>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="rating" class="p-col-fixed">City/Province*:</label>
                <div class="p-col">
                    <Dropdown v-model="selectedProvinceDropdown" :options="provinceDropdown" @change="onProvinceChange()" optionLabel="province_name" :class="{'p-invalid':v$.province.$error}">
                    </Dropdown>
                    <small v-if="v$.province.$error" class="p-error">{{v$.province.required.$message.replace('Value', 'Province')}}</small>  
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="tel" class="p-col-fixed">Tel*:</label>
                <div class="p-col">
                    <InputMask v-model="transportationForm.tel" mask="999 999-999?9" :class="{'p-invalid':v$.tel.$error}"/>
                    <small v-if="v$.tel.$error" class="p-error">{{v$.tel.required.$message.replace('Value', 'Phone number')}}</small>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="email" class="p-col-fixed">Email:</label>
                <div class="p-col">
                    <InputText id="email" v-model="transportationForm.email" type="text" :class="{'p-invalid':v$.email.$error}"/>
                    <small v-if="v$.email.$error" class="p-error">{{v$.email.$errors[0].$message.replace('Value', 'Email')}}</small>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="website" class="p-col-fixed">Website:</label>
                <div class="p-col">
                    <InputText id="website" v-model="transportationForm.website" type="text" />
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="address" class="p-col-fixed">Address:</label>
                <div class="p-col">
                    <InputText id="address" v-model="transportationForm.address" type="text" />
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-fixed">
                <div v-if="FormType == 'add'">
                    <Button v-if="can('create', 'transportation')" label="Save" icon="pi pi-save" type="submit" class="p-button-success" />
                </div>
                <div v-else>
                    <Button v-if="can('update', 'transportation')" label="Update" icon="pi pi-save" type="submit" class="p-button-warning" />
                </div>
            </div>
            <div class="p-col-fixed">
                <InlineMessage v-if="messages.isMsgShow" :severity="messages.severity" class="p-ml-4">{{ messages.content }}</InlineMessage>
            </div>	
        </div>
    </div>
</form>
</template>

<script>
import { ref, reactive, onMounted, computed} from 'vue';
import { useStore } from 'vuex';
import { useAbility } from '@casl/vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

import TransportationService from '../service/TransportationService'
import CambodiaProvinceService from '../service/CambodiaProvinceService'

export default {
    props: {
        FormType: String
    },
    emits: ['RefreshTransportationList'],
    setup(props, { emit }) {
        const { can } = useAbility()
        const store = useStore()

        let transportationForm = reactive({})
        let transportation_info = reactive({}) //Get data from store to fill in form
        let transportation_id = ref(0) //Save transporationn id to use in update form
        let isCompanyNameValid = ref(true)

        let provinceDropdown = ref([])
        const selectedProvinceDropdown = ref()

        let messages =  computed(() => store.state.showMsg)

        //On Create
        const transService = ref(new TransportationService())
        const provinceService = ref(new CambodiaProvinceService())

        onMounted(() => {
            initTransportationForm()

            if(props.FormType == 'update') {
                transportation_info = store.state.transportationInfo.transportationUpdateForm
                
                transportation_id.value = transportation_info.id
                setProvinceDropdown(transportation_info.province)
                Object.assign(transportationForm, transportation_info)
            } else {
                setProvinceDropdown()
            }
        })

        //Methods
        const initTransportationForm = () =>{
            const initForm = {
                company_name: '',
                province: '',
                tel: '',
                email: '',
                website: '',
                address: '',
            }

            Object.assign(transportationForm, initForm)
        }

        const addTrasportation  = () => {
            if(validateForm()) {
                transService.value.addTransportation(transportationForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            showMessage('warn', data.message, 5000)
                            isCompanyNameValid.value = false
                        } else {
                            initTransportationForm()
                            emit('RefreshTransportationList')
                            showMessage('success', 'Transportation company was added successfully.', 3500)
                            v$.value.$reset() //Reset validations
                            isCompanyNameValid.value = true
                        }
                    }
                })
            }
        }

        const updateTransportation = () => {
            if(validateForm()) {
                delete transportationForm.id //DELETE ID FIELD BECAUSE IT DOESN'T NECESSARY TO UPDATE
                
                const update_data = {
                    company_name: transportationForm.company_name == transportation_info.company_name ? '' : transportationForm.company_name,
                    province: transportationForm.province,
                    tel: transportationForm.tel,
                    email: transportationForm.email,
                    website: transportationForm.website,
                    address: transportationForm.address,
                }
                transService.value.updateTransportation(transportation_id.value, update_data).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400){
                            showMessage('warn', data.message, 5000)
                            isCompanyNameValid.value = false
                        } else {
                            showMessage('success', 'Transportation company was updated successfully.', 3500)
                            emit('RefreshTransportationList')
                            isCompanyNameValid.value = true
                        }
                    }
                })
            }
        }

        const setProvinceDropdown = async (province_name) => {
            await provinceService.value.getProvinces().then(data => provinceDropdown.value = data)

            if (province_name != ''){
                const province = provinceDropdown.value.filter(item => item.province_name == province_name)
                selectedProvinceDropdown.value = province[0]
            }
        }

        const showMessage = (severity, content, life) => {
            let message = {
                isMsgShow: true, 
                severity: severity, 
                content: content
            }

            store.dispatch('showMsg', message)
            if(life) {
                setTimeout(() => {
                    store.dispatch('showMsg', { isMsgShow: false, severity: '', content: '' })
                }, life);
            }
        }

        const onProvinceChange = () => {
            transportationForm.province = selectedProvinceDropdown.value.province_name
        }

        //Form Validations
        let rules = computed(() =>  {
            return {
                company_name: { required },
                province: { required },
                tel: { required },
                email: { email },
            }
        })
        const v$ = useVuelidate(rules, transportationForm)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            can,
            v$,
            transportationForm,
            addTrasportation,
            updateTransportation,
            isCompanyNameValid,

            provinceDropdown,
            selectedProvinceDropdown,
            onProvinceChange,

            messages,
        }
    },
}
</script>

<style lang="scss" scoped>
.transportation-form {
    margin-top: 5px;

    label {
        width: 130px;
    }
}
</style>