<template>
<div class="transportation-contract">
    <div class="filter-bar">
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-6 p-sm-6">
                <div style="display: flex;">
                    <label style="min-width: 120px" for="company_name">Company name:</label>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <AutoComplete inputClass="custom-autocomplete-icon" v-model="selectedTransportation" :suggestions="filteredTransportation" @complete="searchTransportationCompany($event)" @item-select="onTransportationCompanySelect($event)" field="company_name" />
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-6 p-sm-6">
                <div style="display: flex;">
                    <label style="min-width: 120px" for="contract">Contract:</label>
                    <Dropdown style="width: 100%" v-model="selectedContract" :options="contract_list" optionLabel="contract_name" >
                    </Dropdown>
                </div>
            </div>
        </div>
        <div class="p-fluid p-grid" style="margin-top: 0px">
            <label for="season" style="width: 120px" class="p-col-12 p-md-1 p-lg-1">Contract period:</label>
            <div class="p-col">
                <Dropdown v-model="selectedPeriod" :options="contractPeriod_List" optionLabel="validity"></Dropdown> 
            </div>
            <div class="p-col-fixed">
                <Button label="Update Contract" @click="openTransportationContractForm()" style="width: 8.7rem" class="p-button-sm" />
            </div>
        </div>
    </div>
    <div class="vehiclerate-datatable card">
        <DataTable
            id="Vehicle-Rate"
            :value="vehicleRateList"
            responsiveLayout="scroll"
            scrollHeight="530px"
            selectionMode="single"
            v-model:selection="selectedVehicleRate"
            dataKey="id"
        >
            <Column field="desc" header="Description" :style="{'min-width':'230px'}"></Column>
            <Column field="car_rate" header="Car" :style="{'min-width':'60px'}"></Column>
            <Column field="van_rate" header="Van" :style="{'min-width':'60px'}"></Column>
            <Column field="v25s_rate" header="25 Seats" :style="{'min-width':'75px'}"></Column>
            <Column field="v35s_rate" header="35 Seats" :style="{'min-width':'75px'}"></Column>
            <Column field="v45s_rate" header="45 Seats" :style="{'min-width':'75px'}"></Column>
        </DataTable>

    </div>
</div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { useToast } from "primevue/usetoast";
import dayjs from 'dayjs';

import TransportationService from '../service/TransportationService'

export default {
    setup() {
        const toast = useToast()
        const transportations = ref()
        const selectedTransportation = ref()
        const filteredTransportation = ref()

        let contract_list = ref([])
        let selectedContract = ref()

        let contractPeriod_List = ref([])
        let selectedPeriod = ref()

        let transportation_id = ref()

        //On Create
        const transService = ref(new TransportationService())

        //Methods
        const searchTransportationCompany = (event) => {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    filteredTransportation.value = [...transportations.value];
                }
                else {
                    transService.value.searchTransportation(event.query.toLowerCase()).then(data => {
                        filteredTransportation.value = data
                    })
                }
            }, 250);
        }

        const onTransportationCompanySelect = (event) => {
            transportation_id.value = event.value.id
            getTransportationContract(transportation_id.value)
            
            // contractPeriod_List.value.length = 0
        }

        const openTransportationContractForm = () => {
            if(selectedTransportation.value) {
                window.open('/transportation/update-contract?transportationid='+selectedTransportation.value.id, '_blank');
            }
        }

        const getTransportationContract = (transportationID) => {
            transService.value.getContract(transportationID).then((data) => {
                if(!data.errorResponse) {
                    contract_list.value = data
                    selectedContract.value = data[0]
                } else {
                    toast.add({severity:'warn', summary: 'Getting Contract Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            })
        }

        watchEffect(() => {
            if(selectedContract.value) {
                getContractPeriod(selectedContract.value.id)
            }
        })

        function getContractPeriod(contract_id) {
            transService.value.getTransportationContractPeriod(contract_id).then(data => {
                contractPeriod_List.value = data.map(item => {
                    return {
                        id: item.id,
                        validity: item.valid_from + ' - ' + item.valid_to,
                        rest_contract_id: item.rest_contract_id
                    }
                })

                //Find index in contract period for current year and select dropdown
                if(contractPeriod_List.value) {
                    const data_idx = data.findIndex(item => dayjs(item.valid_from).isSame(dayjs(), 'year'))
                    if(data_idx == -1) {
                        selectedPeriod.value = contractPeriod_List.value[0]
                    } else {
                        selectedPeriod.value = contractPeriod_List.value[data_idx]
                    }
                }
            })
        }

        //==============VEHICLE RATE==============
        let vehicleRateList = ref([])
        let selectedVehicleRate = ref({})

        watchEffect(() => {
            if(selectedPeriod.value) {
                getVehicleRate()
            }
        })

        function getVehicleRate () {
            const periodid = selectedPeriod.value.id
            
            transService.value.getTransportationRate(periodid).then((data) => {
                if(data.length > 0) {
                    vehicleRateList.value = data
                } else {
                    vehicleRateList.value.length = 0
                }
            })
        }

        return {
            transportations,
            selectedTransportation,
            filteredTransportation,
            searchTransportationCompany,
            onTransportationCompanySelect,
            openTransportationContractForm,

            contract_list,
            selectedContract,

            contractPeriod_List,
            selectedPeriod,

            vehicleRateList,
            selectedVehicleRate,
        }
    },
}
</script>

<style lang="scss" scoped>
.vehiclerate-datatable {
    height: 550px;
}
.p-input-icon-left i {
    z-index: 1;
}
</style>