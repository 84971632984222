<template>
<TabView class="custom-tabview">
    <TabPanel header="Transportation List">
        <div class="transport-list">
            <div class="search-bar">
                <div class="p-input-icon-left search-field">
                    <i class="pi pi-search" />
                    <InputText
                        type="text"
                        placeholder="Search by name"
                        v-model="filters['global'].value"
                    />
                </div>
                <Button v-if="can('create', 'transportation')" label="Add" @click="openTransportationFormDialog('add')" class="p-button-sm" icon="pi pi-plus" />
            </div>   
            
            <div class="content-section implementation">
                <div class="transportation-datatable card">
                    <DataTable
                        :value="transportationsList"
                        responsiveLayout="scroll"
                        scrollHeight="430px"
                        v-model:selection="selectedTransportation" 
                        selectionMode="single"
                        v-model:filters="filters"
                        :globalFilterFields="['company_name']"
                        dataKey="id"
                    >
                        <Column field="id" header="ID" :style="{'min-width':'70px'}"></Column>
                        <Column field="company_name" header="Company Name" :style="{'min-width':'180px'}"></Column>
                        <Column field="province" header="City/Province" :style="{'min-width':'120px'}"></Column>
                        <Column field="tel" header="Tel." :style="{'min-width':'110px'}"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="website" header="Website"></Column>
                        <Column field="address" header="Address" :style="{'max-width':'220px'}">
                            <template #body="slotProps">
                                <div class="truncate-addr">
                                    {{slotProps.data.address}}
                                </div>
                            </template>
                        </Column>

                        <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                            <template #body="slotProps">
                                <Button v-if="can('update', 'transportation')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="openTransportationFormDialog('update', slotProps.index)"/>
                                <Button v-if="can('delete', 'transportation')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteTransportation(slotProps.data)" />
                            </template>
                        </Column>
                    </DataTable>
                    <ConfirmDialog></ConfirmDialog>
                </div> 

                <Dialog v-model:visible="showTransportationFormDialog" :style="{width: '50rem', margin: '0px 5px'}" :modal="true">
                    <template #header>
                        <h5 style="margin: 0px">{{dialogHeader}}</h5>
                    </template>
                    <TransportationForm :FormType="form_type" @RefreshTransportationList="getTransportationData()" />
                </Dialog>
                
                <div class="tabmenu card">
                    <TabView lazy class="tabview-custom-header">
                        <TabPanel>
                            <template #header>
                                <i class="pi pi-phone"></i>
                                <span>Contact Person</span>
                            </template>
                            <div v-if="displayComponent">
                                
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    </TabPanel>

    <TabPanel header="Contract Rate">
        <TransportationContractRate />
    </TabPanel>
</TabView>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import {FilterMatchMode} from 'primevue/api';
import TransportationForm from '../components/TransportationForm.vue';
import TransportationContractRate from './TransportationContractRate.vue';

import TransportationService from '../service/TransportationService';

export default {
    setup() {
        const { can } = useAbility()
        const store = useStore()
        const confirm = useConfirm()
        const toast = useToast()

        let transportationsList = ref([])
        let selectedTransportation = ref()
        const displayComponent = ref(false)
        const filters = ref({
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        });
        
        let dialogHeader = ref('')
        let form_type = ref('')
        const showTransportationFormDialog = ref(false)

        //On Create
        const transService = ref(new TransportationService())

        onMounted(() => {
            getTransportationData()
        })

        //=================Method=================
        const getTransportationData = () => {
            transService.value.getTransportation().then((data) => {
                if(!data.errorResponse) {
                    transportationsList.value = data;
                } else {
                    toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            });
        }

        const openTransportationFormDialog = (formtype, transporationindex) => {
            showTransportationFormDialog.value = true
            form_type.value = formtype
            
            if(formtype == 'add'){
                dialogHeader.value = 'Add Transportation Company'
            } else {
                dialogHeader.value = 'Update Transportation Company'
                store.dispatch('transportation_updateform', transportationsList.value[transporationindex])
            }
        }

        const confirmDeleteTransportation = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.company_name +'" ?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const transportationID = data.id
                    transService.value.deleteTransportation(transportationID).then((data) => {
                        if(!data.errorResponse){
                            getTransportationData()
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});
                        }
                    })
                }
            });
        }

        return {
            can,
            filters,
            transportationsList,
            selectedTransportation,
            getTransportationData,
            
            displayComponent,
            dialogHeader,
            form_type,
            openTransportationFormDialog,
            showTransportationFormDialog,

            confirmDeleteTransportation,
        }
    },
    components: {
        TransportationForm,
        TransportationContractRate,
    }
}
</script>

<style lang="scss" scoped>
.transportation-datatable {
    height: 450px;
}

.tabmenu {
    height: auto;
    min-height: 21.8rem;
    padding: 0px 10px;
}
</style>